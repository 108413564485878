<template>
    <div>
        <template v-if="layoutView.view !== 'mobile'">
            <div class="gibs-footer-desktop-view">
                <hr class="gibs-divider divider-1">
                <div class="gibs-logo-contact-container">
                    <div class="gibs-footer-logo-container">
                        <div class="gibs-footer-logo">
                            <nuxt-icon name="gibs-logo-full25Years" />
                        </div>
                        <!-- <img class="gibs-footer-logo" src='/gibs-logo-full.svg'/> -->
                    </div>
                    <div class="gibs-details-container">
                        <div class="gibs-address">
                            <span>Address</span> <br>
                            <p>
                                26 Melville Road <br>
                                Illovo, Johannesburg <br>
                                2196
                            </p>
                        </div>
                        <div class="gibs-contacts">
                            <span>Contact</span><br>
                            <strong>We're available to help</strong><br>
                            <p>
                                Monday to Friday, 08h00 - 17h00.
                            </p>
                            <p class="gibs-call-container">
                                Call &nbsp
                                <GibsControlTextLink :textLinkSpec="contactNumberLink" />
                            </p>
                            <p>
                                <GibsControlTextLink :textLinkSpec="SendEmailLink" />
                            </p>
                            <GibsControlSolidButton id="subscribe-newsletter-btn" class="gibs-subscribe-btn"
                                :solidButtonSpec="newsletterSpec" @click="emitOpenModal" />
                        </div>
                    </div>
                </div>

                <hr class="gibs-divider divider-2">

                <div class="gibs-accreditation-list">
                    <div class="gibs-accreditation" v-for="item in accreditationList">
                        <NuxtLink :title="item.name" :id="`accreditation-${item.name}`" :to='item.url' external target="_blank">
                            <nuxt-icon :class="item.size" :name='item.icon' />
                        </NuxtLink>
                    </div>
                </div>

                <hr class="gibs-divider divider-2">

                <div class="gibs-extra-links-container">
                    <div class="gibs-copyright-and-links">
                        <div id="gibs-extra-link-list" class="gibs-links-container">
                            <span class="gibs-footer-extra-links">
                                <NuxtLink id="extra-link-gibsMba" :to='gibsMbaUrl'>GIBS MBA</NuxtLink>
                            </span>
                            <span class="gibs-footer-extra-links">
                                <NuxtLink id="extra-link-info" :to='infoUrl'>Info Central</NuxtLink>
                            </span>
                            <span class="gibs-footer-extra-links">
                                <NuxtLink id="extra-link-up" :to='upUrl' external target="_blank">University of Pretoria</NuxtLink>
                            </span>
                            <span class="gibs-footer-extra-links">
                                <NuxtLink id="extra-link-vacancies" :to='vacanciesUrl' external target="_blank">Vacancies</NuxtLink>
                            </span>
                            <span class="gibs-footer-extra-links">
                                <NuxtLink id="extra-link-sitemap" :to="sitemapUrl">Sitemap</NuxtLink>
                            </span>
                            <span class="gibs-footer-extra-links">
                                <NuxtLink id="extra-link-legal" :to='legalUrl'>Legal</NuxtLink>
                            </span>
                            <span class="gibs-footer-extra-links">
                                <NuxtLink id="extra-link-contactus" :to="contactusUrl">Contact Us</NuxtLink>
                            </span>
                        </div>
                        <div class="gibs-copyright-container">
                            <span id="gibs-copyright" class="gibs-copyright">
                                Copyright &#169; {{ year }} GIBS. All Rights Reserved.
                            </span>
                        </div>
                    </div>

                    <ul id="gibs-social-media" class="gibs-social-media">
                        <li v-for="item in socialMediaList">
                            <NuxtLink :title="item.name" :id="`social-media-${item.name}`" :to='item.url' external target="_blank">
                                <nuxt-icon class="social-media-icon" :name='item.icon' />
                            </NuxtLink>
                        </li>
                    </ul>
                </div>
            </div>
        </template>

        <template v-else-if="layoutView.view === 'mobile'">
            <div class="gibs-footer-mobile-view">
                <hr class="gibs-divider mobile-divider-1">

                <div class="gibs-mobile-social-media-container">
                    <ul id="gibs-social-media" class="gibs-mobile-social-media">
                        <li v-for="item in socialMediaList">
                            <NuxtLink :title="item.name" :id="`social-media-${item.name}`" :to='item.url' external target="_blank">
                                <nuxt-icon class="social-media-icon" :name='item.icon' />
                            </NuxtLink>
                        </li>
                    </ul>
                </div>

                <div class="gibs-mobile-contacts-container">
                    <div class="gibs-mobile-contacts">
                        <span>
                            <GibsControlTextLink :textLinkSpec="SendEmailLink" />
                        </span>
                        <span>
                            <GibsControlTextLink :textLinkSpec="contactNumberLink" />
                        </span>
                    </div>
                </div>

                <hr class="gibs-divider mobile-divider-2">

                <div class="gibs-mobile-accreditation-container">
                    <div class="gibs-mobile-accreditation-list">
                        <div class="gibs-accreditation" v-for="item in accreditationList">
                            <NuxtLink :title="item.name" :id="`accreditation-${item.name}`" :to='item.url' external target="_blank">
                                <nuxt-icon :class="item.size" :name='item.icon' />
                            </NuxtLink>
                        </div>
                    </div>
                </div>

                <hr class="gibs-divider mobile-divider-3">

                <div id="gibs-mobile-extra-link-list" class="gibs-mobile-extra-link-container">
                    <span class="gibs-footer-extra-links">
                        <NuxtLink id="extra-link-gibsMba" :to='gibsMbaUrl'>GIBS MBA</NuxtLink>
                    </span>
                    <span class="gibs-footer-extra-links">
                        <NuxtLink id="extra-link-info" :to='infoUrl'>Info Central</NuxtLink>
                    </span>
                    <span class="gibs-footer-extra-links">
                        <NuxtLink id="extra-link-up" :to='upUrl' external target="_blank">University of Pretoria</NuxtLink>
                    </span>
                    <span class="gibs-footer-extra-links">
                        <NuxtLink id="extra-link-vacancies" :to='vacanciesUrl' external target="_blank">Vacancies</NuxtLink>
                    </span>
                    <span class="gibs-footer-extra-links">
                        <NuxtLink id="extra-link-sitemap" :to='sitemapUrl'>Sitemap</NuxtLink>
                    </span>
                    <span class="gibs-footer-extra-links">
                        <NuxtLink id="extra-link-legal" :to='legalUrl'>Legal</NuxtLink>
                    </span>
                    <span class="gibs-footer-extra-links">
                        <NuxtLink id="extra-link-contactus" :to='contactusUrl'>Contact Us</NuxtLink>
                    </span>
                </div>
                <div class="gibs-subscribe-mobile-container">
                    <GibsControlSolidButton id="subscribe-newsletter-btn" class="gibs-subscribe-btn"
                        :solidButtonSpec="newsletterSpec" @click="emitOpenModal" />
                </div>

                <div id="gibs-copyright" class="gibs-mobile-copyright-container">
                    Copyright &#169; {{ year }} GIBS. All Rights Reserved.
                </div>
            </div>
        </template>
    </div>
</template>

<script setup lang="ts">

const props = defineProps(["layoutView", "isModalOpen"]); 

const emit = defineEmits(['toggleModal']);

function emitOpenModal() {
  emit('toggleModal');
}

const year = new Date().getFullYear();

const accreditationList = [
{
        name: "amba",
        url: "https://www.associationofmbas.com/",
        size: "icon-lg",
        icon: "accreditation/footer-amba-logo"
    },
    {
        name: "aacsb",
        url: "http://www.aacsb.edu/accreditation",
        size: "icon-base",
        icon: "accreditation/footer-aacsb-logo"
    },
    {
        name: "equis",
        url: "https://www.efmdglobal.org/accreditations-assessments/business-schools/equis/",
        size: "icon-lg",
        icon: "accreditation/footer-equis-logo"
    },
    {
        name: "aabs",
        url: "http://www.aabschools.com/",
        size: "icon-xl",
        icon: "accreditation/footer-aabs-logo"
    },
    {
        name: "gbsn",
        url: "https://gbsn.org/",
        size: "icon-xl",
        icon: "accreditation/footer-gbsn-logo"
    },
    {
        name: "embac",
        url: "https://embac.org/",
        size: "icon-lg",
        icon: "accreditation/footer-embac-logo"
    },
    {
        name: "efmd",
        url: "https://efmdglobal.org/",
        size: "icon-lg",
        icon: "accreditation/footer-efmd-logo"
    },
    {
        name: "sabsa",
        url: "http://www.sabsa.co.za/",
        size: "icon-lg",
        icon: "accreditation/footer-sabsa-logo"
    },
    {
        name: "che",
        url: "https://www.che.ac.za/",
        size: "icon-base",
        icon: "accreditation/footer-che-logo"
    },
    {
        name: "qcto",
        url: "https://www.qcto.org.za/",
        size: "icon-lg",
        icon: "accreditation/footer-qcto-logo"
    },
    {
        name: "rrbm",
        url: "https://www.rrbm.network/",
        size: "icon-base",
        icon: "accreditation/footer-rrbm-logo"
    },
    {
        name: "aom",
        url: "https://aom.org/",
        size: "icon-xl",
        icon: "accreditation/footer-aom-logo"
    },
    {
        name: "prme",
        url: "http://www.unprme.org/",
        size: "icon-base",
        icon: "accreditation/footer-prme2-logo"
    },
    {
        name: "ceeman",
        url: "http://www.ceeman.org/",
        size: "icon-base",
        icon: "accreditation/footer-ceeman-logo"
    },
    {
        name: "unicon",
        url: "https://www.uniconexed.org/",
        size: "icon-lg",
        icon: "accreditation/footer-unicon-logo"
    }
    // <!-- -------------------------- -->
];

const socialMediaList = [
    {
        name: "facebook",
        url: "https://www.facebook.com/GIBSsa",
        icon: "social-media/fb-logo"
    },
    {
        name: "twitter",
        url: "https://twitter.com/gibs_sa",
        icon: "social-media/x-logo"
    },
    {
        name: "instagram",
        url: "https://www.instagram.com/gibsbusinessschool/",
        icon: "social-media/instagram-logo"
    },
    {
        name: "youtube",
        url: "https://www.youtube.com/user/GIBSBusinessSchool",
        icon: "social-media/youtube-logo"
    },
    {
        name: "linkedin",
        url: "https://www.linkedin.com/school/gibssa/",
        icon: "social-media/linkedin-logo"
    },
    {
        name: "tiktok",
        url: "https://www.tiktok.com/@gibsbusinessschool",
        icon: "social-media/tiktok-logo"
    },
];

const gibsMbaUrl = "/programmes/master-of-business-administration--mba-";
const infoUrl = "/info-central";
const upUrl = "https://www.up.ac.za/";
// Gustav 2024/05/13 - Vacancies to link directly to LinkedIn URL until content is suppied for vacancies page.
//const vacanciesUrl = "/pages/vacancies"; // REMOVE external in Nuxt link
const vacanciesUrl = "https://www.linkedin.com/school/gibssa/jobs/?viewAsMember=true";
const sitemapUrl = "/sitemap";
const legalUrl = "/pages/legal";
const contactusUrl = "/contact-us";

const newsletterSpec: IControlButton = {
    buttonText: "Subscribe to our Newsletter",
    class: "",
    enableUrl: false,
    externalSource: false,
    sourceUrl: "",
}

const SendEmailLink: IControlButton = {
    buttonElName: "gibs-email",
    buttonText: "Send us an Email",
    class: "gibs-email",
    enableUrl: true,
    externalSource: false,
    sourceUrl: "mailto:info@gibs.co.za?subject=Inquiry from GIBS Website Contact Form&body=Dear GIBS Team,%0d%0a%0d%0aI am reaching out through the GIBS website's contact form regarding the following:",
}

const contactNumberLink: IControlButton = {
    buttonElName: "gibs-cell",
    buttonText: "+27 (0) 11 771 4000",
    class: "gibs-cell",
    enableUrl: true,
    externalSource: false,
    sourceUrl: "tel:+27117714000",
}

</script>
